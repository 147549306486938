import { Draggable } from "@hello-pangea/dnd";
import React, { useEffect, useRef, useState } from "react";
import { Card, Form, Spinner, OverlayTrigger, Popover } from "react-bootstrap";
import { fetchVertederos } from '../api/fetchVertederos'
import '../pages/styles/Home.css'

const VertederoItem = ({ item, index, nextItem, setChangesMade }) => {
    const [placement, setPlacement] = useState('right');
    const cardRef = useRef(null);

    const [vertederos, setVertederos] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    const [newName, setNewName] = useState(item.client_name);

    const [errorMessage, setErrorMessage] = useState("");
    const [showError, setShowError] = useState(false)

    useEffect(() => {
        const calculatePlacement = () => {
            if (cardRef.current) {
                const { right } = cardRef.current.getBoundingClientRect();
                const windowWidth = window.innerWidth;
                if (right > windowWidth / 2) {
                    setPlacement('left');
                } else {
                    setPlacement('right');
                }
            }
        };

        calculatePlacement();
        window.addEventListener('resize', calculatePlacement);

        return () => {
            window.removeEventListener('resize', calculatePlacement);
        };
    }, []);

    const renderPopover = (props) => (
        <Popover id={`popover-main-${item.station_id}`} {...props}>
            <Popover.Header as="h3" style={{ backgroundColor: '#1D3557', color: '#ffffff' }}>Estación {item.client_name}</Popover.Header>
            <Popover.Body className="p-3">
                <p><strong>Dirección: </strong>{item.contact_address_complete}</p>
                <p><strong>Operación: </strong>{item.operation_type}</p>
                <p className="mb-0"><strong>Coordenadas: </strong>{item.location}</p>
            </Popover.Body>
        </Popover>
    );

    const handleError = (error) => {
        if (error.message) {
            setErrorMessage(error.message);
        } else {
            setErrorMessage("Ocurrió un error al procesar la solicitud. Por favor, intente más tarde.");
        }
        setShowError(true);
    };


    const handleFetchVertederos = () => {
        setIsFetching(true);

        fetchVertederos()
            .then((fetchedData) => {
                setVertederos(fetchedData);
            })
            .catch((error) => {
                console.log("Error occurred while fetching stations:", error.message);
                handleError(error);
            })
            .finally(() => {
                setIsFetching(false);
            });
    };

    const handleChangeVertedero = (event) => {
        setNewName(event.target.value);
        item.new_name = event.target.value;
        setChangesMade(true);
    };

    return (
        <>
            <OverlayTrigger
                trigger={['hover', 'focus']}
                placement={placement}
                overlay={renderPopover}
                delay={500}
            >
                <Card bg={"secondary"} className='mb-2' ref={cardRef} style={{ cursor: 'pointer', padding: '12px 10px' }}>
                    <Card.Body style={{ padding: '0px' }}>
                        <div
                            className="d-flex justify-content-between align-items-center"
                            style={{
                                margin: 0,
                                whiteSpace: 'normal',
                                overflowWrap: 'break-word',
                                wordBreak: 'break-word',
                                overflow: 'hidden'
                            }}
                        >
                            <Form>
                                <Form.Select
                                    aria-label="Select operation"
                                    size="sm"
                                    className='draggable-item'
                                    style={{
                                        margin: 0,
                                        border: "0px",
                                        padding: "0px 0px 0px 10px",
                                        width: '198px',
                                        height: '44px',
                                        fontSize: '14px',
                                        backgroundColor: 'rgb(108, 117, 125)'
                                    }}
                                    onFocus={handleFetchVertederos}
                                    onChange={handleChangeVertedero}
                                >
                                    {isFetching ? (
                                        <Spinner />
                                    ) : (
                                        <>
                                            <option value={item.client_name}>{item.client_name}</option>
                                            {vertederos.map((vertedero) => {
                                                return (
                                                    <option key={vertedero[4]} value={vertedero[4]}>{vertedero[4]}</option>
                                                )
                                            })}
                                        </>
                                    )}

                                </Form.Select>
                            </Form>
                        </div>
                        <Card.Footer className="d-flex justify-content-between" style={{ padding: '0px' }}>
                            <small>Llegada: {item.arrival_time}</small>
                            <span>
                                {(newName !== item.client_name) ? (
                                    <span style={{ color: 'red' }}>*</span>
                                ) : (
                                    <></>
                                )}
                                <strong> {item.operation_type.charAt(0).toUpperCase()}</strong>
                            </span>
                        </Card.Footer>
                    </Card.Body>
                </Card>
            </OverlayTrigger>
        </>
    );
};

export default VertederoItem;