import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import DraggableItem from "./DraggableItem";
import TotalCost from "./TotalCost";
import SelectDriver from "./SelectDriver";
import '../pages/styles/DraggableLists.css'
import '../pages/styles/Home.css'
import UndraggableItem from "./UndraggableItem";
import VertederoItem from "./VertederoItem";

const DraggableLists = ({ lists, setLists, setChangesMade, setStandByListUsed, firstIteration, drivers, setDrivers, standByList, setStandByList }) => {

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const sourceListId = result.source.droppableId;
    const destinationListId = result.destination.droppableId;

    const updatedLists = JSON.parse(JSON.stringify(lists.stations));
    const updatedStandByList = [...standByList];

    const isSourceStandBy = sourceListId === "standby";
    const isDestinationStandBy = destinationListId === "standby";

    const sourceListIndex = updatedLists.findIndex(
      (list) => String(list.vehicle_id) === sourceListId
    );
    const destinationListIndex = updatedLists.findIndex(
      (list) => String(list.vehicle_id) === destinationListId
    );

    let movedItem;
    let nextItem = null;

    // Handle the removal from the source list
    if (isSourceStandBy) {
      [movedItem] = updatedStandByList.splice(result.source.index, 1);

      // Check if the next item in the StandBy list is a 'Vertedero' and store it
      if (result.source.index < updatedStandByList.length) {
        const potentialNextItem = updatedStandByList[result.source.index];
        if (potentialNextItem.operation_type === 'Vertedero') {
          nextItem = updatedStandByList.splice(result.source.index, 1)[0];
        }
      }
    } else if (sourceListIndex !== -1) {
      [movedItem] = updatedLists[sourceListIndex].itinerary.splice(
        result.source.index,
        1
      );

      // Check if the next item is a 'Vertedero' and store it
      if (result.source.index < updatedLists[sourceListIndex].itinerary.length) {
        const potentialNextItem = updatedLists[sourceListIndex].itinerary[result.source.index];
        if (potentialNextItem.operation_type === 'Vertedero') {
          nextItem = updatedLists[sourceListIndex].itinerary.splice(result.source.index, 1)[0];
        }
      }
    } else {
      console.error("Invalid source list index.");
      return;
    }

    // Prevent moving 'Base Acceso Sur'
    if (
      movedItem.operation_type === "Base" &&
      movedItem.client_name === "Base Acceso Sur"
    ) {
      console.error(
        "Cannot move 'Base Acceso Sur' items. Found moved item:",
        movedItem
      );
      return;
    }

    // Add moved item (and nextItem if exists) to destination list
    if (isDestinationStandBy) {
      setStandByListUsed(true);

      let adjustedIndex = result.destination.index;

      // Check if the task above in the standby list is Cambio or Retiro
      if (result.destination.index > 0) {
        const previousTask = updatedStandByList[result.destination.index - 1];
        if (previousTask && (previousTask.operation_type === 'Cambio' || previousTask.operation_type === 'Retiro')) {
          adjustedIndex += 1; // Increment the index by 1 to account for Vertedero
        }
      }

      // Now add the moved task and its associated Vertedero
      updatedStandByList.splice(adjustedIndex, 0, movedItem);
      if (nextItem) {
        updatedStandByList.splice(adjustedIndex + 1, 0, nextItem);
      }

    } else if (destinationListIndex !== -1) {
      const destinationList = updatedLists[destinationListIndex];

      let adjustedIndex = result.destination.index;

      // Check the task above to see if it is Cambio or Retiro
      if (result.destination.index > 0) {
        const previousTask = destinationList.itinerary[result.destination.index - 1];
        if (previousTask && (previousTask.operation_type === 'Cambio' || previousTask.operation_type === 'Retiro')) {
          adjustedIndex += 1; // Increment the index by 1 to account for Vertedero
        }
      }

      // If the moved item is a Vertedero, don't link it to any task, just treat it as isolated
      if (movedItem.operation_type === 'Vertedero') {
        // Prevent linking a Vertedero to a task if it is found in the second spot
        if (movedItem.original_index === 1) {
          updatedStandByList.splice(adjustedIndex, 0, movedItem);
        } else {
          // Add it to the destination itinerary like normal
          destinationList.itinerary.splice(adjustedIndex, 0, movedItem);
        }
      } else {
        destinationList.itinerary.splice(adjustedIndex, 0, movedItem);
      }

      if (nextItem) {
        destinationList.itinerary.splice(adjustedIndex + 1, 0, nextItem);
      }

      // Ensure 'Base Acceso Sur' is always first and last
      const firstBaseIndex = destinationList.itinerary.findIndex(
        (item) =>
          item.operation_type === "Base" && item.client_name === "Base Acceso Sur"
      );

      const lastBaseIndex = destinationList.itinerary
        .slice()
        .reverse()
        .findIndex(
          (item) =>
            item.operation_type === "Base" && item.client_name === "Base Acceso Sur"
        );

      // Move 'Base Acceso Sur' to the first position if not already there
      if (firstBaseIndex > 0) {
        const [firstBase] = destinationList.itinerary.splice(firstBaseIndex, 1);
        destinationList.itinerary.unshift(firstBase);
      }

      // Move 'Base Acceso Sur' to the last position if not already there
      if (lastBaseIndex !== 0) {
        const [lastBase] = destinationList.itinerary.splice(
          destinationList.itinerary.length - 1 - lastBaseIndex,
          1
        );
        destinationList.itinerary.push(lastBase);
      }
    } else {
      console.error("Invalid destination list index.");
      return;
    }

    // Save the updated lists and ensure both tasks stay linked for future moves
    setLists((prevLists) => ({
      ...prevLists,
      stations: updatedLists,
      total_benefit: prevLists.total_benefit,
    }));
    setStandByList(updatedStandByList);
    setChangesMade(true);
  };

  const calculateDelta = (current, original) => {
    if (original === undefined) return { value: null, formatted: null };
    const delta = current - original;
    const formatted = `${delta >= 0 ? '+' : ''}${delta.toFixed(1)}`;
    return { value: delta, formatted: formatted };
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Row style={{ flexWrap: "nowrap" }} className="pt-4">
        {/* Render each station list */}
        {lists.stations.map((list, index) => {
          const firstIterationList = firstIteration?.stations.find(
            (flist) => flist.vehicle_id === list.vehicle_id
          );
          const delta = calculateDelta(
            list.route_cost,
            firstIterationList?.route_cost
          );

          return (
            <Col
              key={list.vehicle_id}
              style={{ flex: "0 0 auto", width: "250px", padding: "0 10px" }}
            >
              <h4>Vehículo {list.vehicle_id + 1}</h4>
              <p><small>Contenedores necesarios: {list.containers}</small></p>
              <SelectDriver
                key={index}
                drivers={drivers}
                index={index}
                setDrivers={setDrivers}
              />
              <TotalCost
                delta={delta}
                route_cost={list.route_cost}
                cost_by_distance={list.cost_by_distance}
                cost_by_duration={list.cost_by_duration}
              />
              <Card className="p-1">
                <Droppable droppableId={String(list.vehicle_id)} key={`droppable-${list.vehicle_id}`}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{ minHeight: "100vh" }}
                    >
                      {list.itinerary &&
                        list.itinerary.map((item, index) => {
                          const isDepot = item.client_name === "Base Acceso Sur";
                          const isVertedero = item.operation_type === "Vertedero";
                          const isSecondPosition = index === 1; // Check if it's the second position
                          const nextItem =
                            ((item.operation_type === "Retiro" || item.operation_type === "Cambio") && list.itinerary[index + 1]?.operation_type === "Vertedero")
                              ? list.itinerary[index + 1]
                              : null;

                          // If the Vertedero is in the second position, render it by itself
                          if (isVertedero && isSecondPosition) {
                            return (
                              <VertederoItem
                                key={item.station_id}
                                item={item}
                                index={index}
                                nextItem={null} // No nextItem because it's standalone
                                setChangesMade={setChangesMade}
                              />
                            );
                          }

                          // Combine station_id and index to ensure unique key
                          const key = `station-${item.task_id}-${index}`;

                          return isDepot ? (
                            <UndraggableItem key={key} item={item} />
                          ) : isVertedero ? (
                            <React.Fragment key={key} />
                          ) : (
                            <DraggableItem
                              setChangesMade={setChangesMade}
                              key={key}
                              item={item}
                              index={index}
                              nextItem={nextItem}
                            />
                          );
                        })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Card>
            </Col>
          );
        })}

        {/* Render Stand By List */}
        <Col
          style={{
            flex: "0 0 auto",
            width: "250px",
            padding: "0 10px",
          }}
        >
          <h4 className="pb-2">Stand By</h4>
          <Card className="p-1">
            <Droppable droppableId="standby">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{ minHeight: "100vh" }}
                >
                  {standByList.map((item, index) => {
                    const isVertedero = item.operation_type === "Vertedero";
                    const isSecondPosition = index === 1; // Check if it's the second position
                    const nextItem =
                      ((item.operation_type === "Retiro" || item.operation_type === "Cambio") &&
                        standByList[index + 1]?.operation_type === "Vertedero")
                        ? standByList[index + 1]
                        : null;

                    // If the Vertedero is in the second position, render it by itself
                    if (isVertedero && isSecondPosition) {
                      return (
                        <DraggableItem
                          key={`standalone-${item.station_id}-${index}`}
                          item={item}
                          index={index}
                          nextItem={null} // No nextItem because it's standalone
                          setChangesMade={setChangesMade}
                        />
                      );
                    }

                    // Combine station_id and index to ensure unique key
                    const key = `standby-${item.station_id}-${index}`;

                    return (
                      <DraggableItem
                        key={key}
                        item={item}
                        index={index}
                        nextItem={nextItem}  // Pass nextItem to group Vertederos
                        setChangesMade={setChangesMade}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Card>
        </Col>
      </Row>
    </DragDropContext>
  );
};

export default DraggableLists;