import React, { useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es'; // Import Spanish locale
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('es', es);

const SelectDriversModal = ({
    show,
    setShowSelectDrivers,
    drivers,
    selectedDrivers,
    setSelectedDrivers,
    selectedDate,
    setSelectedDate,
    handleFetchData,
    availableContainers,
    setAvailableContainers,
}) => {
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedDrivers([]);
        } else {
            setSelectedDrivers(
                drivers.map((driver) => ({
                    id: driver.id,
                    needs_vertedero: false, // Default to false for all when selecting all
                }))
            );
        }
        setSelectAll(!selectAll);
    };

    const handleDriverToggle = (driverId) => {
        const existingDriver = selectedDrivers.find((driver) => driver.id === driverId);

        if (existingDriver) {
            // If the driver is already selected, remove it
            setSelectedDrivers(
                selectedDrivers.filter((driver) => driver.id !== driverId)
            );
        } else {
            // If the driver is not selected, add it with default `needs_vertedero: false`
            setSelectedDrivers([
                ...selectedDrivers,
                { id: driverId, needs_vertedero: false },
            ]);
        }
    };

    const handleNeedsVertederoToggle = (driverId) => {
        setSelectedDrivers((prevSelectedDrivers) =>
            prevSelectedDrivers.map((driver) =>
                driver.id === driverId
                    ? { ...driver, needs_vertedero: !driver.needs_vertedero }
                    : driver
            )
        );
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleStart = () => {
        handleFetchData();
        setShowSelectDrivers();
    };

    const isComenzarDisabled =
        selectedDrivers.length === 0 || !selectedDate || availableContainers < 1;

    return (
        <Modal show={show} onHide={() => setShowSelectDrivers(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Seleccione choferes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {drivers ? (
                        <>
                            <Form.Check
                                label="Seleccionar todos"
                                checked={selectAll}
                                onChange={handleSelectAll}
                            />
                            {drivers.map((driver) => {
                                const selectedDriver = selectedDrivers.find(
                                    (d) => d.id === driver.id
                                );
                                return (
                                    <div
                                        key={driver.id}
                                        className="d-flex align-items-center justify-content-between mb-2"
                                    >
                                        {/* Driver Checkbox */}
                                        <Form.Check
                                            label={driver.name}
                                            checked={!!selectedDriver} // Check if driver is selected
                                            onChange={() => handleDriverToggle(driver.id)}
                                        />
                                        {/* Needs Vertedero Switch */}
                                        {selectedDriver && (
                                            <Form.Check
                                                type="switch"
                                                id={`needs-vertedero-switch-${driver.id}`}
                                                label="Descargar"
                                                checked={selectedDriver.needs_vertedero}
                                                onChange={() =>
                                                    handleNeedsVertederoToggle(driver.id)
                                                }
                                                className="ms-3"
                                            />
                                        )}
                                    </div>
                                );
                            })}
                            <div style={{ marginTop: "20px" }}>
                                <label className="pe-3">Seleccionar Fecha: </label>
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    dateFormat="dd/MM/yyyy"
                                    locale="es"
                                    className="form-control"
                                    placeholderText="Selecciona una fecha"
                                    minDate={new Date()}
                                    maxDate={new Date().setDate(new Date().getDate() + 10)}
                                    filterDate={(date) => date.getDay() !== 0} // Exclude Sundays
                                />
                            </div>
                            <Form.Group
                                controlId="numericInput"
                                className="d-flex align-items-center"
                                style={{ marginTop: "20px" }}
                            >
                                <Form.Label className="pe-3">
                                    Contenedores disponibles:
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    value={availableContainers}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setAvailableContainers(value);
                                    }}
                                    min={1}
                                    max={99}
                                    style={{ width: "100px" }} // Adjust the width as needed
                                />
                            </Form.Group>
                        </>
                    ) : (
                        <Spinner />
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    onClick={handleStart}
                    disabled={isComenzarDisabled} // Disable button conditionally
                >
                    Comenzar
                </Button>
                <Button
                    variant="secondary"
                    onClick={() => setShowSelectDrivers(false)}
                >
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SelectDriversModal;