import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const AlertModal = ({ show, onHide, alertMessage, historyMessage }) => {
    const [showHistoryMessage, setShowHistoryMessage] = useState(false)

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Atención</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{alertMessage}</p>

                {showHistoryMessage ? (
                    <>
                        <p style={{ marginBottom: '0px' }}><strong>Historial: </strong></p>
                        <p>{historyMessage}</p>
                        <Button onClick={() => setShowHistoryMessage(false)}>Cerrar</Button>
                    </>
                ) : (
                    <Button onClick={() => setShowHistoryMessage(true)}>Ver historial</Button>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AlertModal;
